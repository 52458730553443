/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.login-container {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  width: 40%;
  margin: auto;
  text-align: center;
}

.login-form h2 {
  margin-bottom: 20px;
  color: #333;
}

.login-page-input {
  margin-bottom: 15px;
  text-align: left;
}

.login-page-input label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.login-page-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  background-color: #f6f6f6;
  border: none;
  padding: 14px 0px;
}

.login-button {
  background-color: #56baed;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 50%;
  padding: 14px 0px;
}

.login-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.login-page {
  display: flex;
  justify-content: center;
  height: 100vh;
}
