.result-page-container {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 20px auto;
  text-align: center;
  height: auto;
}

.result-page-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.boxes-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.box {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: auto;
  width: 100% !important;
  box-sizing: border-box;
}

.array-box {
  width: 100%;
}

.box h3 {
  margin-bottom: 10px;
  color: #333;
}

.box ul {
  list-style: none;
  padding: 0;
}

.box ul li {
  margin-bottom: 5px;
  display: inline;
}

.separator {
  margin: 0 1px;
}

.number + .separator + br {
  display: none;
}

.number {
  display: inline-block;
  margin: 5px;
}

.share-button {
  background-color: green;
  color: white;
  margin-top: 10px;
  border: none;
  padding: 10px;
  border-radius: 6px;
}

.share-all-button {
  background-color: blue;
  color: white;
  margin-top: 20px;
  border: none;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.share-all-button:hover {
  background-color: darkblue;
}
