.main-container {
  width: 62%;
  margin: auto;
}

.custom-form-container {
  width: 100%;
  margin: auto;
  text-align: center;
}

.custom-form h2 {
  margin-bottom: 20px;
  color: #333;
}

.custom-form {
  width: 49%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #efefef;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-weight: 600;
}

.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
.form-group input,
.form-group textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.submit-button {
  background-color: #5cb85c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  width: "50%";
}

.submit-button:hover {
  background-color: #0056b3;
}

.game-all-forms {
  display: flex;
  justify-content: space-between;
}

.game-form-button {
  width: 50%;
  margin-bottom: 10px;
  padding: 2px;
}

.game-form-button-active {
  width: 50%;
  background-color: #5bc0de;
  color: white;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 2px;
}

.custom-forms {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.form-group-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-device-width: 991px) {
  .main-container {
    width: 90% !important;
  }
  .custom-forms {
    flex-direction: column;
  }
  .custom-form {
    width: 100%;
    box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .form-group-container {
    display: block;
    width: 100%;
  }
  .form-group {
    width: 100% !important;
  }
}

@media (max-width: 1450px) and (min-width: 991px) {
  .main-container {
    width: 90% !important;
  }
}

@media (max-width: 1024px) {
  .box {
    flex: 1 1 calc(50% - 40px);
    width: 60% !important;
  }
}

@media (max-width: 768px) {
  .result-page-container {
    padding: 30px;
  }

  .box {
    flex: 1 1 calc(100% - 40px);
    width: 85% !important;
  }
  .box ul li {
    font-size: 12px !important;
  }
}

@media (max-width: 480px) {
  .result-page-container {
    padding: 20px;
  }

  .box {
    padding: 15px;
    width: 95% !important;
  }

  .box ul li {
    font-size: 10px !important;
  }

  .share-button {
    font-size: 14px;
    padding: 8px;
  }
}
